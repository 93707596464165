
import { Vue, Component, Prop } from 'vue-property-decorator'

import SliderArrow from '~/components/Icons/SliderArrow.vue'
import Quotation from '~/components/Icons/Quotation.vue'
import SpeechBubble from '~/components/Icons/SpeechBubble.vue'
import { Review } from '~/lib/review/ReviewProviderInterface'

@Component({
  components: { SliderArrow, Quotation, SpeechBubble },
  data() {
    return {
      siteId: process.env.siteId
    }
  },
})
export default class Reviews extends Vue {
  @Prop({ required: true })
  reviews: Review[]

  index: number = 0

  nextReview() {
    if (this.index < this.reviews.length - 1) {
      this.index = this.index + 1
    } else {
      this.index = 0
    }
  }

  previousReview() {
    if (this.index >= 1) {
      this.index = this.index - 1
    } else {
      this.index = this.reviews.length - 1
    }
  }
}
