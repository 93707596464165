import { render, staticRenderFns } from "./SliderArrow.vue?vue&type=template&id=041ad4b7&scoped=true&"
var script = {}
import style0 from "./SliderArrow.vue?vue&type=style&index=0&id=041ad4b7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041ad4b7",
  null
  
)

export default component.exports